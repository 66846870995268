import { http } from "@/apps/shared/baseService/config";
import axios from "axios";

const base = {
  rota: "marketplaces",
  nome: "marketplaces",
  auth: "cliente"
};

export const service = {
  criar: data => http.post(`/${base.auth}/${base.rota}/create`, data),
  listar: data => http.post(`/${base.auth}/${base.rota}/list`, data),
  atualizar: data => http.post(`/${base.auth}/${base.rota}/update`, data),
  excluir: data => http.post(`/${base.auth}/${base.rota}/delete`, data),
  show_marketplace: data => http.get(`/${base.auth}/${base.rota}/${data}`, {}),
  excluir_muitos: data =>
    http.post(`/${base.auth}/${base.rota}/delete-many`, data),
  getCep: cep => axios.get(`https://viacep.com.br/ws/${cep}/json/`)
};

const module = {
  actions: {
    listar_marketplaces: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .listar(getters.get_marketplaces_filtros)
        .then(res => {
          dispatch("disableLoading");
          commit("set_marketplaces", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          // dispatch("createGlobalMessage", {
          //   type: "error",
          //   message: e.,
          //   timeout: 6000
          // });
          console.log("e", e);
        });
    },
    atualizar_marketplace: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .atualizar({ ...getters.get_marketplace, imagens: getters.getLinks })
        .then(res => {
          dispatch("disableLoading");
          commit("set_marketplace", res.data);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualização Concluída!",
            timeout: 6000
          });
          dispatch("listar_marketplaces");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_marketplace: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .criar({ ...getters.get_marketplace, imagens: getters.getLinks })
        .then(() => {
          dispatch("disableLoading");
          commit("set_marketplace", {});
          commit("setLinks", []);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro Concluído!",
            timeout: 6000
          });
          dispatch("listar_marketplaces");
          commit("set_modal_view_marketplace", false);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_marketplace: ({ dispatch, getters }, payload) => {
      dispatch("enableLoading");
      service
        .excluir(payload)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          dispatch("listar_marketplaces");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_marketplaces: ({ dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .excluir(getters.get_marketplaces_marcados)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão em massa Concluída!",
            timeout: 6000
          });
          dispatch("listar_marketplaces");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    get_cep: ({ dispatch, commit, getters }, cep) => {
      dispatch("enableLoading");

      service
        .getCep(cep)
        .then(res => {
          dispatch("disableLoading");
          commit("set_marketplace", {
            postal_code: res.data.cep,
            address: res.data.logradouro,
            neigthborhood: res.data.bairro,
            uf: res.data.uf,
            ddd: res.data.ddd,
            city: res.data.localidade
          });
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    abre_modal_view_marketplace: ({ commit, getters, dispatch }, payload) => {
      if (payload) {
        commit("set_marketplace", payload);
        commit("setLinks", payload.imagens);
      }
      commit("set_modal_view_marketplace", true);
    },
    show_marketplace({ dispatch, commit }, payload) {
      service
        .show_marketplace(payload)
        .then(res => {
          dispatch("disableLoading");
          commit("set_marketplace", res.data);
          document.title = res.data.loja.nome;
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    fecha_modal_view_marketplace: ({ commit }) => {
      commit("set_marketplace", {});
      commit("setLinks", []);
      commit("set_modal_view_marketplace", false);
    },
    add_marketplace_marcado: ({ commit, getters }, payload) => {
      commit("set_marketplaces_marcados", [
        ...getters.get_marketplaces_marcados,
        payload
      ]);
    },
    remove_marketplace_marcado: ({ commit, getters }, i) => {
      commit(
        "set_marketplaces_marcados",
        ...getters.get_marketplaces_marcados.filter(
          (item, index) => index !== i
        )
      );
    }
  },
  state: {
    marketplace: {},
    marketplaces: [],
    marketplaces_marcados: [],
    marketplaces_filtros: {
      limitPerPage: 10,
      page: 1,
      dataInicio: "",
      dataFim: ""
    },
    modal_view_marketplace: false
  },
  mutations: {
    set_marketplace: (state, payload) => (state.marketplace = payload),
    set_marketplaces: (state, payload) => (state.marketplaces = payload),
    set_marketplaces_marcados: (state, payload) =>
      (state.marketplaces_marcados = payload),
    set_modal_view_marketplace: (state, payload) =>
      (state.modal_view_marketplace = payload)
  },
  getters: {
    get_marketplace: state => state.marketplace,
    get_marketplaces: state => state.marketplaces,
    get_marketplaces_marcados: state => state.marketplaces_marcados,
    get_modal_view_marketplace: state => state.modal_view_marketplace,
    get_marketplaces_filtros: state => state.marketplaces_filtros
  }
};

const routes = [
  // {
  //   path: `/`,
  //   component: () => import("./views/Listar.vue"),
  //   name: base.nome
  // },
  {
    path: `/dashboard`,
    component: () => import("./views/Listar.vue"),
    name: base.nome
  },
  {
    path: `/:id`,
    props: true,
    component: () => import("./views/Visualizar.vue"),
    name: "Visualizar Loja"
  },
  {
    path: `/acompanhar/:id`,
    props: true,
    component: () => import("./views/AcompanhamentoDoCliente.vue"),
    name: "Acompanhamento do Cliente"
  },
  // {
  //   path: `/auto-atendimento/:id`,
  //   props: true,
  //   component: () => import("./views/Autoatendimento.vue"),
  //   name: "Visualizar Loja"
  // },
  // {
  //   path: `/fila/:id`,
  //   props: true,
  //   component: () => import("./views/Senhas.vue"),
  //   name: "Visualizar fila de pedidos"
  // },
  // {
  //   path: `/mesas/:id`,
  //   props: true,
  //   component: () => import("./views/Mesas.vue"),
  //   name: ""
  // },
  {
    path: `/site`,
    props: true,
    component: () => import("./views/Site.vue"),
    name: ""
  }
];

export default {
  service,
  module,
  routes
};
