import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { UserLoggedModule } from "@/apps/2#userLogged/store";
import { SharedModule } from "@/apps/shared/store";
import { AuthModule } from "@/apps/2#auth/store";
import Permissao from "./apps/1#Permissoes/meta";
import Funcionario from "./apps/9#Funcionarios/meta";
import { tipos_de_despesas_module } from "./apps/26#TiposDeDespesa/store";
import Caixa from "./apps/27#Caixas/meta";
import { formas_de_pagamento_module } from "./apps/28#FormasDePagamento/store";
import Banner from "./apps/37#Banners/meta";
import Endereco from "@/apps/40#Enderecos/meta";
import Marketplace from "./apps/Marketplace/meta";
import Produto from "./apps/41#Produtos/meta";

export const store = new Vuex.Store({
  modules: {
    UserLoggedModule,
    AuthModule,
    SharedModule,
    permissao_module: Permissao.module,
    funcionarios_module: Funcionario.module,
    tipos_de_despesas_module,
    caixas_module: Caixa.module,
    formas_de_pagamento_module,
    banners: Banner.module,
    endereco_module: Endereco.module,
    marketplace_mod: Marketplace.module,
    produto_module: Produto.module
  }
});

export default store;
