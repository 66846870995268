<template>
  <v-app id="app" style="background: #fff;">
    <!-- <video class="video"></video> -->
    <router-view />
    <ModalConfirmAction />
    <ModalLoading />
    <ModalGlobalMessage />
  </v-app>
</template>

<script>
import ModalConfirmAction from "@/apps/shared/components/ModalConfirmAction";
import ModalLoading from "@/apps/shared/components/ModalLoading";
import ModalGlobalMessage from "@/apps/shared/components/ModalGlobalMessage";
// TODO

// TODO: teste
//fixme: teste
//ugly
//optimize
export default {
  components: {
    ModalConfirmAction,
    ModalLoading,
    ModalGlobalMessage
  },
  async created() {
    if ("serial" in navigator) {
      // The Web Serial API is supported.
      // console.log("serial disponível", navigator);
      // const ports = await navigator.serial.getPorts();
      // let mediadevices = navigator.getUserMedia();
      // console.log("lista de portas", ports);
      // console.log("lista de devices", mediadevices);
      // const devices = await navigator.mediaDevices.enumerateDevices();
      let bcord = cordova;
      const cord = bcord ? true : false;
      console.log("devi", cordova);
      if (cord && cordova.platformId == "android") {
        StatusBar.backgroundColorByHexString("#ffffff");
      }
      // navigator.getMedia(
      //   // permissoes
      //   {
      //     video: true,
      //     audio: true
      //   },

      //   // callbackSucesso
      //   function(localMediaStream) {
      //     var video = document.querySelector(".video");
      //     video.src = window.URL.createObjectURL(localMediaStream);
      //     video.onloadedmetadata = function(e) {
      //       console.log("e", e);
      //       // Faz algo com o vídeo aqui.
      //     };
      //   },

      //   // callbackErro
      //   function(err) {
      //     console.log("O seguinte erro ocorreu: " + err);
      //   }
      // );
    }
  }
};
</script>

<style>
.fonte-micro {
  font-size: 12px;
}
.fonteMini {
  font-size: 8pt;
  color: #cccccc;
}
.click {
  cursor: pointer;
}
.item-table {
  border-radius: 6px;
  border: 1px solid #e2e2e2;
}
.bg-table {
  background: #f2f2f2;
}
.column {
  flex-direction: column;
}
.bg_white {
  background: #fff;
}
.expande-horizontal {
  display: flex;
  width: 100%;
}
.item-list-title {
  padding: 6px;
  background: #e2e2e2;
  border-radius: 6px;
  margin-bottom: 6px;
}
.inflar {
  display: flex;
  width: 100%;
  min-height: 100vh;
}
.inflar-porcentagem {
  display: flex;
  width: 100%;
  height: 100%;
}
.centraliza {
  justify-content: center;
  align-items: center;
}
.wrap {
  flex-wrap: wrap;
}
.linha {
  flex-direction: row;
}
.fonteCash {
  /* font-family: 'Fredoka One', cursive; */
  font-family: "Righteous", cursive;
}
.fonteTitulo {
  font-family: "Righteous", cursive;
}
.fonte {
  font-family: "Montserrat", sans-serif;
  /* font-family: "JetBrains Mono", monospace; */
  /* font-family: "Righteous", cursive; */
  /* font-family: 'Fredoka One', cursive; */
}
.fonte-bold {
  font-weight: 400;
}
.item-contained {
  border-radius: 6px;
  padding: 12px;
  background: #333;
}
/* .menuAtivoHome {
  background: linear-gradient(to right, #03A9F4, #56ccf2);
  border-radius: 1px;
  box-shadow: 0 3px 5px 2px rgba(90, 62, 2, 0.2);
}
.bg-card-dash {
  background: linear-gradient(#56ccf2, #03A9F4);
} */
.menuAtivoHome {
  background: linear-gradient(to right, #0575e6, #2196f3);
  border-radius: 1px;
  box-shadow: 0 3px 5px 2px rgba(90, 62, 2, 0.2);
}
.bg-card-dash {
  background: linear-gradient(#0575e6, #2196f3);
}
.item {
  height: 40px;
}
.vertical-60 {
  min-height: 60vh;
}
.vitrify {
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
}
.fume {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
}
</style>
