import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import { dashboard_routes } from "./apps/dashboard/router";
import { logged_user_routes } from "./apps/2#userLogged/router";
import { auth_routes } from "./apps/2#auth/router";
import Permissao from "./apps/1#Permissoes/meta";
import Funcionario from "./apps/9#Funcionarios/meta";
import { tipos_de_despesas_routes } from "./apps/26#TiposDeDespesa/router";
import Caixa from "./apps/27#Caixas/meta";
import { formas_de_pagamento_routes } from "./apps/28#FormasDePagamento/router";
import Banner from "./apps/37#Banners/meta";
import Endereco from "./apps/40#Enderecos/meta";
import Marketplace from "./apps/Marketplace/meta";

const routes = [
  ...dashboard_routes,
  ...logged_user_routes,
  ...auth_routes,
  ...Permissao.routes,
  ...Funcionario.routes,
  ...tipos_de_despesas_routes,
  ...Caixa.routes,
  ...formas_de_pagamento_routes,
  ...Banner.routes,
  ...Endereco.routes,
  ...Marketplace.routes
];

export const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;
