import Vue from "vue";
import store from "@/store";
import App from "./App.vue";
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import mask from "vue-the-mask";
import moment from "moment";
import "moment/locale/pt-br";
import "@/apps/shared";
import { db, storage, auth } from "./apps/shared/firebase";
import Helper from "./helper";
import VueHtml2pdf from "vue-html2pdf";
import run from "./apps/shared/Onoff";

export const EventBus = new Vue();

Vue.use(VueHtml2pdf);
Vue.use(mask);
Vue.prototype.$moment = moment;
Vue.config.productionTip = false;

const theme = {
  primary: "#F44336",
  secondary: "#e1673c",
  third: "#47cf72",
  // primary: "#03A9F4",
  icon_color: "#2196F3",
  icon_color_secondary: "#DF683E",
  logo:
    "https://firebasestorage.googleapis.com/v0/b/egestor-876cd.appspot.com/o/logo.png?alt=media&token=cb370788-7daf-4242-b9f7-c38649394779",
  token_img: ""
};

Vue.prototype.$theme = theme;
Vue.prototype.$db = db;
Vue.prototype.$auth = auth;
Vue.prototype.$storage = storage;
Vue.prototype.$helper = new Helper();
Vue.prototype.$run = run;

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
