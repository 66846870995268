<template>
  <v-container
    style="flex-direction: column; background: rgba(0,0,0,0) "
    fluid
    class="molde pa-0 ma-0"
  >
    <v-layout column>
      <v-app-bar
        app
        dense
        color="transparent"
        :fixed="$vuetify.breakpoint.smAndDown"
        class="elevation-0"
        :style="
          $vuetify.breakpoint.smAndDown
            ? 'margin-bottom: 0px; border-radius: 0px'
            : 'border-radius: 0px;margin-bottom: 0px'
        "
        style="justify-content: center; align-items: center;"
      >
        <v-btn @click="drawer = !drawer" icon>
          <v-icon color="grey">mdi-menu</v-icon>
          <!-- <v-icon :color="$theme.icon_color">mdi-menu</v-icon> -->
        </v-btn>

        <v-btn v-if="this.icon !== undefined" @click="goBack()" icon>
          <v-avatar class="elevation-0">
            <v-icon :color="$theme.icon_color"> arrow_back </v-icon>
          </v-avatar>
        </v-btn>

        <!-- <span class="fonte ml-2" :style="`color: ${$theme.primary}`">{{
          title
        }}</span> -->
        <span class="fonte ml-2 fonte-bold grey--text"> Bem Vindo(a) </span>

        <!-- <v-spacer></v-spacer> -->

        <!-- <v-flex class="px-2" md3>
            <v-text-field hide-details solo-inverted dark flat dense v-model="pesquisa" placeholder="Faça uma busca" :color="$theme.primary" append-icon="mdi-magnify"></v-text-field>
        </v-flex> -->

        <v-spacer></v-spacer>

        <v-menu bottom>
          <template v-slot:activator="{ on }">
            <v-btn :color="$theme.primary" icon v-on="on">
              <v-icon :color="$theme.icon_color">mdi-bell-outline</v-icon>
            </v-btn>
          </template>

          <v-list width="300">
            <v-list-item
              v-for="notification in getNotifications.received"
              :key="notification._id"
              @click="() => item.action()"
            >
              <v-list-item-avatar>
                <v-icon>mdi-bell-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="fonte">{{
                  notification.title
                }}</v-list-item-title>
                <v-list-item-subtitle class="fonte">{{
                  notification.message
                }}</v-list-item-subtitle>
                <v-list-item-subtitle
                  class="orange--text fonte"
                  style="font-size: 9pt;"
                  >{{
                    $moment(notification.created_at).format("llll")
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu left bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" :color="$theme.primary">
              <v-icon :color="$theme.icon_color"
                >mdi-account-circle-outline</v-icon
              >
            </v-btn>
          </template>

          <v-list nav dense>
            <v-list-item to="/logged-user">
              <v-list-item-title>{{ getLoggedUser.nome }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-title>sair</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu v-show="options" left bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-show="options && $vuetify.breakpoint.smAndDown"
              icon
              :color="$theme.primary"
              v-on="on"
            >
              <v-icon :color="$theme.icon_color">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="item in options"
              :key="item.nome"
              @click="() => item.action()"
            >
              <v-list-item-title class="fonte">{{
                item.nome
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-main class="molde">
        <div
          v-if="$vuetify.breakpoint.smAndUp"
          class="expande-horizontal px-2 py-3"
        >
          <v-btn class="mr-4" @click="$router.go(-1)" icon>
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <span
            :style="`font-size: 18pt; color: ${$theme.primary}`"
            class="fonte"
          >
            {{ title }}
          </span>
          <v-spacer></v-spacer>
          <v-btn
            v-for="item in options"
            :color="$theme.primary"
            class="elevation-0 animated flipInX delay-1s mr-1"
            @click="item.action()"
            :key="item.nome"
          >
            <v-icon class="mr-1" color="#fff"> {{ item.icon }} </v-icon>
            <span class="white--text text-capitalize fonte">{{
              item.nome
            }}</span>
          </v-btn>
        </div>
        <slot></slot>
      </v-main>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["title", "options", "icon", "action", "origem"],
  data() {
    return {
      drawer: true,
      getNotifications: {
        received: []
      }
    };
  },
  computed: {
    ...mapGetters([
      "getLoggedUser",
      "get_permission_user"
      // 'getNotifications'
    ])
  },
  methods: {
    ...mapActions([
      // 'listNotifications',
      "logout"
    ]),
    listNotifications() {},
    goBack() {
      if (this.action !== undefined) {
        this.action();
      } else {
        this.$router.go(-1);
      }
    }
  },
  created() {
    this.drawer = this.$vuetify.breakpoint.smAndUp;
    this.listNotifications();
  }
};
</script>

<style>
.centraliza-tudo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.molde {
  display: flex;
  width: 100%;
  background: #e5e5e5;
}
</style>
