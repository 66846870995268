export const mutations = {
  setModalViewTipoDeDespesa(state, payload) {
    state.modalViewTipoDeDespesa = payload;
  },
  setTiposDeDespesas(state, payload) {
    state.tiposDeDespesas = payload;
  },
  setTipoDeDespesa(state, payload) {
    state.tipoDeDespesa = payload;
  },
  setTipoDeDespesaMarcadas(state, payload) {
    state.tiposDeDespesaMarcadas = payload;
  }
};

export default {};
