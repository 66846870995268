import { http } from "@/apps/shared/baseService/config";
import axios from "axios";

const base = {
  rota: "enderecos",
  nome: "enderecos",
  auth: "client"
};

export const service = {
  criar: data => http.post(`/${base.auth}/${base.rota}/create`, data),
  listar: data => http.post(`/${base.auth}/${base.rota}/list`, data),
  atualizar: data => http.post(`/${base.auth}/${base.rota}/update`, data),
  excluir: data => http.post(`/${base.auth}/${base.rota}/delete`, data),
  getCep: cep => axios.get(`https://viacep.com.br/ws/${cep}/json/`),
  escolher_endereco_principal: data =>
    http.post(`/${base.auth}/${base.rota}/choice-default-address`, data)
};

const module = {
  actions: {
    listar_enderecos: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .listar(getters.get_enderecos_filtros)
        .then(res => {
          dispatch("disableLoading");
          commit("set_enderecos", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    atualizar_endereco: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .atualizar({ ...getters.get_endereco, imagens: getters.getLinks })
        .then(res => {
          dispatch("disableLoading");
          commit("set_endereco", res.data);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualização Concluída!",
            timeout: 6000
          });
          dispatch("listar_enderecos");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_endereco: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .criar({ ...getters.get_endereco, imagens: getters.getLinks })
        .then(() => {
          dispatch("disableLoading");
          commit("set_endereco", {});
          commit("setLinks", []);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro Concluído!",
            timeout: 6000
          });
          dispatch("listar_enderecos");
          commit("set_modal_view_endereco", false);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_endereco: ({ dispatch, getters }, payload) => {
      dispatch("enableLoading");
      service
        .excluir(payload)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          dispatch("listar_enderecos");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    get_cep: ({ dispatch, commit, getters }, cep) => {
      dispatch("enableLoading");

      service
        .getCep(cep)
        .then(res => {
          dispatch("disableLoading");
          commit("set_endereco", {
            postal_code: res.data.cep,
            address: res.data.logradouro,
            neigthborhood: res.data.bairro,
            uf: res.data.uf,
            ddd: res.data.ddd,
            city: res.data.localidade
          });
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    abre_modal_view_endereco: ({ commit, getters, dispatch }, payload) => {
      if (payload) {
        commit("set_endereco", payload);
        commit("setLinks", payload.imagens);
      }
      commit("set_modal_view_endereco", true);
    },
    fecha_modal_view_endereco: ({ commit }) => {
      commit("set_endereco", {});
      commit("setLinks", []);
      commit("set_modal_view_endereco", false);
    }
  },
  state: {
    endereco: {},
    enderecos: {
      docs: [],
      page: 1,
      totalPages: 1
    },
    enderecos_filtros: {
      limitPerPage: 10,
      page: 1
    },
    modal_view_endereco: false
  },
  mutations: {
    set_endereco: (state, payload) => (state.endereco = payload),
    set_enderecos: (state, payload) => (state.enderecos = payload),
    set_modal_view_endereco: (state, payload) =>
      (state.modal_view_endereco = payload)
  },
  getters: {
    get_endereco: state => state.endereco,
    get_enderecos: state => state.enderecos,
    get_modal_view_endereco: state => state.modal_view_endereco,
    get_enderecos_filtros: state => state.enderecos_filtros
  }
};

const routes = [
  {
    path: `/${base.rota}`,
    component: () => import("./views/Index.vue"),
    name: base.nome
  }
];

export default {
  service,
  module,
  routes
};
