import { http } from "@/apps/shared/baseService/config";

class Service {
  update(data) {
    return http.post("/admin/formas-de-pagamentos/update", data);
  }
  create(data) {
    return http.post("/admin/formas-de-pagamentos/create", data);
  }
  list() {
    return http.post("/admin/formas-de-pagamentos/list");
  }
}

export default new Service();
