import Service from "../service";
import { router } from "@/router";

export const actions = {
  listarTiposDeDespesas: ({ commit, dispatch }, page) => {
    dispatch("enableLoading");

    Service.list()
      .then(res => {
        dispatch("disableLoading");
        commit("setTiposDeDespesas", res.data);
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 6000
        });
      });
  },
  criarTipoDeDespesa({ commit, dispatch, getters }) {
    dispatch("enableLoading");

    if (getters.getTipoDeDespesa._id) {
      Service.update(getters.getTipoDeDespesa)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro concluído com sucesso",
            timeout: 6000
          });
          commit("setModalViewTipoDeDespesa", false);
          dispatch("listarDisciplinas");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    } else {
      Service.create(getters.getTipoDeDespesa)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro concluído com sucesso.",
            timeout: 6000
          });
          commit("setModalViewTipoDeDespesa", false);
          dispatch("listarTiposDeDespesas");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    }
  },
  abreModalViewTipoDeDespesa({ commit, dispatch }, payload) {
    if (payload._id) {
      commit("setTipoDeDespesa", payload);
      commit("setModalViewTipoDeDespesa", true);
    } else {
      commit("setTipoDeDespesa", {});
      commit("setModalViewTipoDeDespesa", true);
    }
  },
  fechaModalViewTipoDeDespesa({ commit }) {
    commit("setModalViewTipoDeDespesa", false);
  },
  marcarUmTipoDeDespesa({ commit, getters }, payload) {
    commit("setTiposDeDespesaMarcadas", [
      ...getters.getTiposDeDespesaMarcadas,
      payload
    ]);
  },
  desmarcaUmTipoDeDespesa({ commit, getters }, disciplina) {
    commit(
      "setTiposDeDespesaMarcadas",
      ...getters.getTiposDeDespesaMarcadas.filter(p => p._id !== payload._id)
    );
  }
};

export default {};
