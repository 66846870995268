import { http } from "@/apps/shared/baseService/config";
import { EventBus } from "@/main.js";

const base = {
  rota: "caixas",
  nome: "Controle de Caixa"
};

export const service = {
  criar: data => http.post(`/admin/${base.rota}/create`, data),
  listar: data => http.post(`/admin/${base.rota}/list`, data),
  listar_admin: data => http.post(`/admin/${base.rota}/list-admin`, data),
  visualizar: data => http.post(`/admin/${base.rota}/view`, data),
  atualizar: data => http.post(`/admin/${base.rota}/update`, data),
  excluir: data => http.post(`/admin/${base.rota}/delete`, data),
  fechar: data => http.post(`/admin/${base.rota}/close-my-box`, data),
  excluir_muitos: data => http.post(`/admin/${base.rota}/delete-many`, data),
  finalizar_compra: data =>
    http.post(`/admin/${base.rota}/finalizar-compra`, data),
  salvar_compra: data => http.post(`/admin/${base.rota}/salvar-compra`, data),
  visualizar_compra: data =>
    http.post(`/admin/${base.rota}/visualizar-compra`, data)
};

const module = {
  actions: {
    salvar_caixa: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .listar(getters.get_caixas_filtros)
        .then(res => {
          if (getters.get_caixas_filtros.filter) {
            if (res.data.docs.length) {
              commit(
                "set_caixa",
                res.data.docs.length
                  ? res.data.docs[0]
                  : { status_atual: "fechado" }
              );
            }
          }

          dispatch("disableLoading");
          commit("set_caixas", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    visualizar_caixa: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .listar(getters.get_caixas_filtros)
        .then(res => {
          if (getters.get_caixas_filtros.filter) {
            if (res.data.docs.length) {
              commit(
                "set_caixa",
                res.data.docs.length
                  ? res.data.docs[0]
                  : { status_atual: "fechado" }
              );
            }
          }

          dispatch("disableLoading");
          commit("set_caixas", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    listar_caixas: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .listar(getters.get_caixas_filtros)
        .then(res => {
          if (getters.get_caixas_filtros.filter) {
            if (res.data.docs.length) {
              commit(
                "set_caixa",
                res.data.docs.length
                  ? res.data.docs[0]
                  : { status_atual: "fechado" }
              );
            }
          }

          dispatch("disableLoading");
          commit("set_caixas", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    listar_caixas_admin: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .listar_admin(getters.get_caixas_filtros)
        .then(res => {
          dispatch("disableLoading");
          commit("set_caixas", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    atualizar_caixa: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .atualizar({ ...getters.get_caixa, imagens: getters.getLinks })
        .then(res => {
          dispatch("disableLoading");
          commit("set_caixa", res.data);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualização Concluída!",
            timeout: 6000
          });
          dispatch("listar_caixas");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_caixa: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .criar({ ...getters.get_caixa, imagens: getters.getLinks })
        .then(res => {
          dispatch("disableLoading");
          commit("set_caixa", res.data);
          commit("setLinks", []);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Caixa aberto!",
            timeout: 6000
          });
          commit("set_modal_abertura_caixa", false);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_caixa: ({ dispatch, getters }, payload) => {
      dispatch("enableLoading");
      service
        .excluir(payload)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          dispatch("listar_caixas");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    fechar_caixa: ({ dispatch, getters, commit }, payload) => {
      dispatch("enableLoading");
      service
        .fechar(getters.get_caixa)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Caixa Fechado!",
            timeout: 6000
          });
          commit("set_modal_fechamento_caixa", false);
          commit("set_caixa", {
            status_atual: "fechado",
            funcionario: {
              nome: ""
            }
          });
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_caixas: ({ dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .excluir(getters.get_caixas_marcados)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão em massa Concluída!",
            timeout: 6000
          });
          dispatch("listar_caixas");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    abre_modal_view_caixa: ({ commit, getters, dispatch }, payload) => {
      if (payload) {
        service.visualizar(payload).then(res => {
          commit("set_caixa", res.data);
          commit("setLinks", res.data.imagens);
          commit("set_modal_view_caixa", true);
        });
      } else {
        commit("set_modal_view_caixa", true);
      }
    },
    fetch_caixa: ({ commit, dispatch }, payload) => {
      dispatch("enableLoading");
      service.visualizar({ id: payload }).then(res => {
        dispatch("disableLoading");
        commit("set_caixa", res.data);
      });
    },
    abre_modal_abertura_caixa: ({ commit, getters, dispatch }, payload) => {
      commit("set_modal_abertura_caixa", true);
    },
    fecha_modal_abertura_caixa: ({ commit, getters, dispatch }, payload) => {
      commit("set_modal_abertura_caixa", false);
    },
    abre_modal_fechamento_caixa: ({ commit, getters, dispatch }, payload) => {
      dispatch("enableLoading");
      service
        .visualizar(getters.get_caixa)
        .then(res => {
          dispatch("disableLoading");
          commit("set_caixa", res.data);
          commit("set_modal_fechamento_caixa", true);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    fecha_modal_fechamento_caixa: ({ commit, getters, dispatch }, payload) => {
      commit("set_modal_fechamento_caixa", false);
    },
    fecha_modal_view_caixa: ({ commit }) => {
      commit("set_modal_view_caixa", false);
      commit("set_caixa", {});
      commit("setLinks", []);
    },
    add_caixa_marcado: ({ commit, getters }, payload) => {
      commit("set_caixas_marcados", [...getters.get_caixas_marcados, payload]);
    },
    remove_caixa_marcado: ({ commit, getters }, i) => {
      commit(
        "set_caixas_marcados",
        ...getters.get_caixas_marcados.filter((item, index) => index !== i)
      );
    },
    add_produto_a_compra: ({ commit, getters, dispatch }, produto) => {
      commit("set_compra", {
        ...getters.get_compra,
        produtos: [...getters.get_compra.produtos, produto]
      });
      dispatch("calcula_valor_total_da_compra");
    },
    remove_produto_da_compra: ({ commit, getters, dispatch }, index) => {
      commit("set_compra", {
        ...getters.get_compra,
        produtos: getters.get_compra.produtos.filter((p, i) => i !== index)
      });
      dispatch("calcula_valor_total_da_compra");
    },
    calcula_valor_total_da_compra: ({ commit, getters }) => {
      const produtos = getters.get_compra.produtos;
      const compra = getters.get_compra;
      let subtotal = 0;
      let acrescimo = compra.acrescimo || 0;
      let desconto = compra.desconto || 0;
      let hasProduct = false;
      produtos.map(item => {
        hasProduct = true;
        subtotal = subtotal + item.seller_price;
      });
      const total_compra =
        subtotal + parseFloat(acrescimo) - parseFloat(desconto);
      commit("set_compra", {
        ...compra,
        preco_total: total_compra,
        subtotal: subtotal
      });
      if (hasProduct) {
        localStorage.last_buy = JSON.stringify({
          ...compra,
          preco_total: total_compra,
          subtotal: subtotal
        });
      }
      commit("set_produto", {});
    },
    finalizar_compra: ({ commit, getters, dispatch }) => {
      dispatch("enableLoading");
      service
        .finalizar_compra({
          ...getters.get_compra,
          caixa: getters.get_caixa,
          compra: getters.get_compra
        })
        .then(res => {
          dispatch("disableLoading");
          commit("set_compra", {
            produtos: [],
            cliente: {},
            tipo: "balcao"
          });
          EventBus.$emit("print_compra", res.data);
          commit("set_produto", {});
          dispatch("createGlobalMessage", {
            type: "success",
            message:
              "O pedido foi realizado, acompanhe mais no Gestor de Pedidos!",
            timeout: 6000
          });
          // dispatch("listar_caixas");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    }
  },
  state: {
    caixa: {
      status_atual: "fechado",
      fundo: ""
    },
    compra:
      localStorage.last_buy && localStorage.last_buy !== "undefined"
        ? JSON.parse(localStorage.last_buy)
        : {
            produtos: [],
            cliente: {},
            tipo: "retirada"
          },
    caixas: {
      docs: [],
      page: 1,
      totalPages: 1
    },
    caixas_marcados: [],
    caixas_filtros: {
      limitPerPage: 10,
      page: 1,
      dataInicio: "",
      dataFim: ""
    },
    modal_view_caixa: false,
    modal_abertura_caixa: false,
    modal_fechamento_caixa: false
  },
  mutations: {
    set_caixa: (state, payload) => (state.caixa = payload),
    set_caixas: (state, payload) => (state.caixas = payload),
    set_caixas_marcados: (state, payload) => (state.caixas_marcados = payload),
    set_modal_view_caixa: (state, payload) =>
      (state.modal_view_caixa = payload),
    set_modal_abertura_caixa: (state, payload) =>
      (state.modal_abertura_caixa = payload),
    set_modal_fechamento_caixa: (state, payload) =>
      (state.modal_fechamento_caixa = payload),
    set_compra: (state, payload) => {
      state.compra = payload;
    }
  },
  getters: {
    get_caixa: state => state.caixa,
    get_caixas: state => state.caixas,
    get_compra: state => state.compra,
    get_caixas_marcados: state => state.caixas_marcados,
    get_modal_view_caixa: state => state.modal_view_caixa,
    get_modal_abertura_caixa: state => state.modal_abertura_caixa,
    get_modal_fechamento_caixa: state => state.modal_fechamento_caixa,
    get_caixas_filtros: state => state.caixas_filtros
  }
};

const routes = [];

export default {
  service,
  module,
  routes
};
