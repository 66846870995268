export const auth_routes = [
  // {
  //   path: "/",
  //   name: "Login",
  //   component: () => import("../Main.vue")
  // },
  {
    path: "/signup/:sponsor_id",
    props: true,
    name: "Cadastro",
    component: () => import("../components/FormSignup")
  }
];

export default {};
