import { http } from "@/apps/shared/baseService/config";

class Service {
  update(data) {
    return http.post("/admin/tipos-de-despesas/update", data);
  }
  create(data) {
    return http.post("/admin/tipos-de-despesas/create", data);
  }
  list() {
    return http.post("/admin/tipos-de-despesas/list");
  }
}

export default new Service();
